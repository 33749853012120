<template>
  <div class='container'>
  <h2>Add A SecondHandVehicles</h2>
   <button @click='save()' type='button' class='btn btn-success'>Save</button>
  <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
	<div class='row'>
            <div class=' col-md-4'><label class='' for='Name'>Name</label><input class=' form-control'   
                id='Name' name='Name' v-model='newSecondHandVehicles.Name' placeholder='Name' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
<div class='col-md-4'>
    <label class for='Image'>Image</label>
    <div v-if='!editingImage'>
      <img v-bind:src='getImageSource(newSecondHandVehicles)' class='previewImage'>
      <button @click='editImage()' type='button' class='btn btn-success'>Update Image</button>
    </div>
    <div v-if='editingImage'>
      <button @click='updateImage()' type='button' class='btn btn-success'>Update</button>
      <button @click='editImage()' type='button' class='btn btn-warning'>Cancel</button>
      <FileUploader @ImageDataChanged='updateImageData' @ImageURLDataChanged='updateImageUrl'/>
    </div>
  </div>
            <div class=' col-md-4'><label class='' for='ImageMeta'>ImageMeta</label><input class=' form-control'   
                id='ImageMeta' name='ImageMeta' v-model='newSecondHandVehicles.ImageMeta' placeholder='ImageMeta' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Year'>Year</label><input class=' form-control'   
                id='Year' name='Year' v-model='newSecondHandVehicles.Year' placeholder='Year' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Mileage'>Mileage</label><input class=' form-control'   
                id='Mileage' name='Mileage' v-model='newSecondHandVehicles.Mileage' placeholder='Mileage' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='VehicleType'>VehicleType</label><input class=' form-control'   
                id='VehicleType' name='VehicleType' v-model='newSecondHandVehicles.VehicleType' placeholder='VehicleType' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Make'>Make</label><input class=' form-control'   
                id='Make' name='Make' v-model='newSecondHandVehicles.Make' placeholder='Make' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Model'>Model</label><input class=' form-control'   
                id='Model' name='Model' v-model='newSecondHandVehicles.Model' placeholder='Model' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Location'>Location</label><input class=' form-control'   
                id='Location' name='Location' v-model='newSecondHandVehicles.Location' placeholder='Location' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Dealarship'>Dealarship</label><input class=' form-control'   
                id='Dealarship' name='Dealarship' v-model='newSecondHandVehicles.Dealarship' placeholder='Dealarship' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Price'>Price</label><input class=' form-control'   
                id='Price' name='Price' v-model='newSecondHandVehicles.Price' placeholder='Price' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Transmission'>Transmission</label><input class=' form-control'   
                id='Transmission' name='Transmission' v-model='newSecondHandVehicles.Transmission' placeholder='Transmission' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='FuelConsumption'>FuelConsumption</label><input class=' form-control'   
                id='FuelConsumption' name='FuelConsumption' v-model='newSecondHandVehicles.FuelConsumption' placeholder='FuelConsumption' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='FuelType'>FuelType</label><input class=' form-control'   
                id='FuelType' name='FuelType' v-model='newSecondHandVehicles.FuelType' placeholder='FuelType' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='BootSpace'>BootSpace</label><input class=' form-control'   
                id='BootSpace' name='BootSpace' v-model='newSecondHandVehicles.BootSpace' placeholder='BootSpace' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='PowerWindows'>PowerWindows</label><input class=' form-control'   
                id='PowerWindows' name='PowerWindows' v-model='newSecondHandVehicles.PowerWindows' placeholder='PowerWindows' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Airbags'>Airbags</label><input class=' form-control'   
                id='Airbags' name='Airbags' v-model='newSecondHandVehicles.Airbags' placeholder='Airbags' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='ABS'>ABS</label><input class=' form-control'   
                id='ABS' name='ABS' v-model='newSecondHandVehicles.ABS' placeholder='ABS' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='CentralLocking'>CentralLocking</label><input class=' form-control'   
                id='CentralLocking' name='CentralLocking' v-model='newSecondHandVehicles.CentralLocking' placeholder='CentralLocking' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='FogLamps'>FogLamps</label><input class=' form-control'   
                id='FogLamps' name='FogLamps' v-model='newSecondHandVehicles.FogLamps' placeholder='FogLamps' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Displacement'>Displacement</label><input class=' form-control'   
                id='Displacement' name='Displacement' v-model='newSecondHandVehicles.Displacement' placeholder='Displacement' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='BoreStroke'>BoreStroke</label><input class=' form-control'   
                id='BoreStroke' name='BoreStroke' v-model='newSecondHandVehicles.BoreStroke' placeholder='BoreStroke' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='KeyFeatures'>KeyFeatures</label><input class=' form-control'   
                id='KeyFeatures' name='KeyFeatures' v-model='newSecondHandVehicles.KeyFeatures' placeholder='KeyFeatures' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='BodyType'>BodyType</label><input class=' form-control'   
                id='BodyType' name='BodyType' v-model='newSecondHandVehicles.BodyType' placeholder='BodyType' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Features'>Features</label><input class=' form-control'   
                id='Features' name='Features' v-model='newSecondHandVehicles.Features' placeholder='Features' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Description'>Description</label><input class=' form-control'   
                id='Description' name='Description' v-model='newSecondHandVehicles.Description' placeholder='Description' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='CommercialVehicle'>CommercialVehicle</label>
            <br/>
                <b-form-checkbox  v-model='newSecondHandVehicles.CommercialVehicle'></b-form-checkbox>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='ListingDate'>ListingDate</label><input class=' form-control'   
                id='ListingDate' name='ListingDate' v-model='newSecondHandVehicles.ListingDate' placeholder='ListingDate' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='LastUpdated'>LastUpdated</label><input class=' form-control'   
                id='LastUpdated' name='LastUpdated' v-model='newSecondHandVehicles.LastUpdated' placeholder='LastUpdated' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
    <br/>
            </div>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  name: 'addSecondHandVehicles',
  created: function() {
  },
  data() {
      return {
          newSecondHandVehicles: {},
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
        editingImage: false 
      }
  },
  components: { 
    FileUploader 
  }, 
  methods: {
    toggleView: function() {
      this.$emit('closeAddSecondHandVehicles')
    },
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
            return true;
        }
    return false;
    },
    save: function() {
    var payload = {
          data: this.newSecondHandVehicles,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('addSecondHandVehiclesSuccess')
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('addSecondHandVehicles', payload);
    }
  }
}
</script>
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
