<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addSecondHandVehiclesModal' hide-footer>
        <addSecondHandVehicles @closeAddSecondHandVehicles='toggleAddSecondHandVehicles()' @addSecondHandVehiclesSuccess='addSecondHandVehiclesSuccess()'></addSecondHandVehicles>
      </b-modal>
      <b-modal ref='editSecondHandVehiclesModal' hide-footer>
        <editSecondHandVehicles
          :editingSecondHandVehicles='currentSecondHandVehicles'
          @closeEditSecondHandVehicles='toggleEditSecondHandVehicles()'
          @editSecondHandVehiclesSuccess='editSecondHandVehiclesSuccess()'
        ></editSecondHandVehicles>
      </b-modal>
      <div class='row'>
        <h2>SecondHandVehicles</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddSecondHandVehicles()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addSecondHandVehicles from '../../components/addSecondHandVehicles';
import editSecondHandVehicles from '../../components/editSecondHandVehicles';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'ImageMeta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'Year',
                field: 'Year',
                sort: 'asc',
              },
              {
                label: 'Mileage',
                field: 'Mileage',
                sort: 'asc',
              },
              {
                label: 'VehicleType',
                field: 'VehicleType',
                sort: 'asc',
              },
              {
                label: 'Make',
                field: 'Make',
                sort: 'asc',
              },
              {
                label: 'Model',
                field: 'Model',
                sort: 'asc',
              },
              {
                label: 'Location',
                field: 'Location',
                sort: 'asc',
              },
              {
                label: 'Dealarship',
                field: 'Dealarship',
                sort: 'asc',
              },
              {
                label: 'Price',
                field: 'Price',
                sort: 'asc',
              },
              {
                label: 'Transmission',
                field: 'Transmission',
                sort: 'asc',
              },
              {
                label: 'FuelConsumption',
                field: 'FuelConsumption',
                sort: 'asc',
              },
              {
                label: 'FuelType',
                field: 'FuelType',
                sort: 'asc',
              },
              {
                label: 'BootSpace',
                field: 'BootSpace',
                sort: 'asc',
              },
              {
                label: 'PowerWindows',
                field: 'PowerWindows',
                sort: 'asc',
              },
              {
                label: 'Airbags',
                field: 'Airbags',
                sort: 'asc',
              },
              {
                label: 'ABS',
                field: 'ABS',
                sort: 'asc',
              },
              {
                label: 'CentralLocking',
                field: 'CentralLocking',
                sort: 'asc',
              },
              {
                label: 'FogLamps',
                field: 'FogLamps',
                sort: 'asc',
              },
              {
                label: 'Displacement',
                field: 'Displacement',
                sort: 'asc',
              },
              {
                label: 'BoreStroke',
                field: 'BoreStroke',
                sort: 'asc',
              },
              {
                label: 'KeyFeatures',
                field: 'KeyFeatures',
                sort: 'asc',
              },
              {
                label: 'BodyType',
                field: 'BodyType',
                sort: 'asc',
              },
              {
                label: 'Features',
                field: 'Features',
                sort: 'asc',
              },
              {
                label: 'Description',
                field: 'Description',
                sort: 'asc',
              },
              {
                label: 'CommercialVehicle',
                field: 'CommercialVehicle',
                sort: 'asc',
              },
              {
                label: 'ListingDate',
                field: 'ListingDate',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addSecondHandVehiclesOpen: false,
      editSecondHandVehiclesOpen: false,
      currentSecondHandVehicles: {}
    };
  },
  components: {
    addSecondHandVehicles,
    editSecondHandVehicles,
    Datatable,
  },
  created: function() {
    this.getSecondHandVehicles();
    this.addSecondHandVehiclesOpen = false;
    this.editSecondHandVehiclesOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getSecondHandVehicles() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getSecondHandVehicles', payload);
    },
    editItem(SecondHandVehicles) {
      this.toggleEditSecondHandVehicles();
      this.currentSecondHandVehicles = SecondHandVehicles;
    },
    toggleAddSecondHandVehicles() {
      if(this.addSecondHandVehiclesOpen)
      {
        this.$refs.addSecondHandVehiclesModal.hide()
      }
      else{
        this.$refs.addSecondHandVehiclesModal.show()
      }
      this.addSecondHandVehiclesOpen = !this.addSecondHandVehiclesOpen;
    },
    addSecondHandVehiclesSuccess() {
      this.toggleAddSecondHandVehicles();
      miniToastr['success']('SecondHandVehicles Added', 'Success', 1000, null);
      this.getSecondHandVehicles();
    },
    toggleEditSecondHandVehicles() {
      if(this.editSecondHandVehiclesOpen)
      {
        this.$refs.editSecondHandVehiclesModal.hide()
      }
      else{
        this.$refs.editSecondHandVehiclesModal.show()
      }
      this.editSecondHandVehiclesOpen = !this.editSecondHandVehiclesOpen;
    },
    editSecondHandVehiclesSuccess() {
      this.toggleEditSecondHandVehicles();
      miniToastr['success']('SecondHandVehicles Edited', 'Success', 1000, null);
      this.getSecondHandVehicles();
    }
  }
};
</script>
<style scoped>
.SecondHandVehiclesThumbnail {
  height: 50px;
}
</style>
